import React from "react";

const SquareFeet = ({color}) => (
    <svg 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6"
    >
            
    <path 
        d="M5.63604 14.1238L7.05026 15.538M8.46447 11.2953L9.87868 12.7096M11.2929 8.46691L12.7071 9.88113M14.1213 5.63849L15.5355 7.0527M2.80762 16.9522L7.05026 21.1948L21.1924 7.0527L16.9498 2.81006L2.80762 16.9522Z" 
        stroke={color}  
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
    </svg>
);

export default SquareFeet;



import React, { useContext } from 'react'
import { type Property } from '../../types/types';
import { PropertyContext } from '../context/property-context';
import Listing from './Listing';

interface Props {
  visible: boolean,
}

const Listings: React.FunctionComponent<Props> = ({ visible }) => {
  const actualVH = `${window.innerHeight - 200}px`;

  const { properties }: { properties: Property[]} = useContext(PropertyContext)
  const listings = properties.map((property) => <Listing key={property.id} property={property} />)
  const visibility = visible ? 'mobile:visible' : 'mobile:invisible mobile:absolute'
  const mobileClasses = `${visibility} mobile:w-full mobile:pt-12`

  return (
    <div className={'flex flex-wrap desktop:w-1/2 overflow-scroll ' + mobileClasses} style={ { height: actualVH }}>
      { 
        properties.length ? 
        listings 
        :
        <h2>No matching properties found in this area</h2>
      }
    </div>
  )
}

export default Listings;

import React from 'react';
import logo from '../../../images/logo/otto_icon_indicator.png'

const OttoBanner = () =>  (
  <div className="flex w-48 max-w-[190px] desktop:w-2/3 bg-cyan text-white justify-start items-center py-2 px-4 text-sm  font-sourcesans">
    <img src={logo} alt="otto homes logo" className='h-6 mr-2' />
    <p>OTTO Exclusive</p>
  </div>
)

export default OttoBanner;

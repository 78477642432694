import React, { useEffect, useRef, useState } from "react";
import { PropertyContextProvider } from "./context/property-context";
import PlaceSearch from "../place-search/place-search";
import Listings from "./components/Listings";
import Button from "../components/Button";
import Map from "./components/Map";
import "./styles/searchbar.css";
import Filters from "./components/Filters";
import MobileControls from "./components/MobileControls";


const SearchPage = () => {
  const [center, setCenter] = useState<[number, number] | null | undefined>(
    undefined
  );
  const [place, setPlace] = useState<{city: string, state: string } | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const filterButtonRef = useRef<HTMLButtonElement>();
  const [mapVisible, setMapVisibility] = useState(true);

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const onPlaceSelected = ({lat, lng, city, state}: {lat: string, lng: string, city: string, state: string}) => {
    createURL({ city, state, lat, lng});
    setCenter([parseFloat(lng), parseFloat(lat)]);
  };

  const toggleMobileVisibility = () => {
    setMapVisibility(!mapVisible);
  };

  const createURL = ({lat, lng, city, state}) => {
    const baseUrl = window.location.href.split("?")[0];
    const formattedUrl = `${baseUrl}?lat=${lat}&lng=${lng}&state=${state}&city=${city}`;
    window.history.pushState({}, "", formattedUrl);
  };

  useEffect(() => {
    const url = new URL(window.location.href.toLowerCase());
    const lat = url.searchParams.get("lat");
    const lng = url.searchParams.get("lng");
    const city = url.searchParams.get("city");
    const state = url.searchParams.get("state");

    if (!!lat && !!lng) {
      setCenter([parseFloat(lng), parseFloat(lat)]);
    } else {
      setCenter(null);
    }

    if (city && state) {
      setPlace({ city, state });
    }
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <PropertyContextProvider>
        <React.StrictMode>
          <div className="desktop:w-2/5 flex mb-3 px-3 relative items-center justify-center mobile:flex-col-reverse">
            <PlaceSearch
              onPlaceSelected={onPlaceSelected}
              redirect={false}
              label={"Where do you want to live?"}
              updateFilters={true}
              place={place}
            />
            <div className="desktop:relative mobile:flex mobile:w-full">
              <Button
                forwardRef={filterButtonRef}
                classNames="ml-3 text-sm mobile:hidden desktop:text-lg py-5"
                onClick={() => { setShowFilters(!showFilters); }}
              >
                filter
              </Button>
              {showFilters && (
                <Filters
                  visible={showFilters}
                  buttonRef={filterButtonRef}
                  setShowFilters={setShowFilters}
                />
              )}
            </div>
          </div>
          <div className="flex relative">
            <MobileControls
             mapVisible={mapVisible}
             toggleShowFilter={toggleFilters}
             toggleMapVisibility={toggleMobileVisibility}
            />
            <>
              <Map visible={mapVisible} center={center}>
                </Map>
                <Listings visible={!mapVisible} />
            </>
          </div>
        </React.StrictMode>
      </PropertyContextProvider>
    </div>
  );
};

export default SearchPage;

import React from 'react'

interface ButtonProps  {
  onClick?: () => any
  children?: any
  classNames?: string
  forwardRef?: React.MutableRefObject<HTMLButtonElement | undefined>
}

const Button: React.FunctionComponent<ButtonProps> = ({ onClick, children, classNames, forwardRef }) => (
  <button ref={forwardRef} className={'hover:text-white rounded-lg hover:bg-cyan px-5 ' + classNames} onClick={onClick}>
    { children }
  </button>
)

export default Button;

import React, { useMemo } from 'react'
import Adjustments from '../../components/icons/Adjustments'
import Map from '../../components/icons/Map'
import List from '../../components/icons/List'

interface Props {
  toggleShowFilter: () => void
  toggleMapVisibility: () => void
  mapVisible: boolean
}

const MobileControls: React.FunctionComponent<Props> = ({toggleShowFilter, toggleMapVisibility, mapVisible}) => {
  const listingButton = useMemo(() => {
    if(mapVisible) {
      return (
        <button className='flex justify-center items-center' onClick={toggleMapVisibility}>
          <span className='mr-2'><List/></span>
          <span>List</span>
        </button>
      )
    } else {
      return (
        <button className='flex justify-center items-center' onClick={toggleMapVisibility}>
          <span className='mr-2'><Map/></span>
          <span>Map</span>
        </button>
      )
    }
  }, [mapVisible])

  return (
    <div className='desktop:hidden z-30 absolute top-0 left-1/2 -translate-x-1/2 bg-cyan text-white rounded-b-lg border-white border-2 p-2 flex '>
      <button className='flex justify-center items-center' onClick={() => { toggleShowFilter(); } }>
        <span className='mr-2'><Adjustments/></span>
        <span>Filters</span>
      </button>
      <span className='mx-4'>|</span>
       {listingButton}
    </div>
  )
}

export default MobileControls;

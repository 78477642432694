import React, { useMemo } from 'react'
import { type Property } from "../../types/types";
import DollarSign from '../../../icons/dollar_sign';

interface Props {
  property: Property
}

const PriceCard:React.FunctionComponent<Props> = ({ property }) => {
  const label = useMemo(() => {
    if (property.listing_source == 'otto' && property.suggested_bid) {
      return `Suggested Bid`
    } else {
      return 'List Price'
    }
  }, [property])

  const price = useMemo(() => {
    if (property.listing_source == 'otto' && property.suggested_bid) {
      return property.suggested_bid
    } else {
      return property.selling_price
    }
  }, [property])

  return (
    <div className="border border-cyan rounded-md py-2 px-2 w-full mb-4 flex justify-around">
      <p className="text-cyan text-xl font-semibold flex items-center"> <span className="mr-1"></span> {label}: {price}</p>
    </div>
  )
}

export default PriceCard;

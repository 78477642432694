import React, { useEffect, useContext, useRef} from 'react'
import { renderToString } from 'react-dom/server';
import { PropertyContext } from '../context/property-context';
import mapbox, { type Marker } from 'mapbox-gl';
import '../styles/map.css'
import placholderImage from '../../../images/placeholder-image.png';
import Bed from '../../components/icons/Bed';
import Bath from '../../components/icons/Bath';
import SquareFeet from '../../components/icons/SquareFeet';

interface MapProps {
  center?: [number, number] | null
  visible: boolean
  children: any
}

let map: any;
let markers: Marker[] = [];

const Map: React.FunctionComponent<MapProps> = ({ center, visible, children }) => {
  const mapboxToken = 'pk.eyJ1IjoibWNpb2NjYTg5IiwiYSI6ImNsODY5a21sNjB0OXEzbnQ5bGxxNnhnangifQ.vrKWYQ8gm_PKGjaypu6K2A'
  const { properties, filters, setFilters, meta } = useContext(PropertyContext)
  const mobileVisibility = visible ? `mobile:visible mobile:relative` : 'mobile:invisible mobile:absolute'
  const mobileClasses = `${mobileVisibility} mobile:w-full`

  const removePlaceLayer = () => {    
    if(map.getLayer('outline')){
      map.removeLayer('outline')
    }

    if(map.getSource('place')) {
      map.removeSource('place')
    }
  }

  useEffect(() => {
    if(meta.boundary) { 
      removePlaceLayer()
      map.addSource('place', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: meta.boundary
        }
      })

      map.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'place',
        'layout': {},
        'paint': {
        'line-color': '#004C60',
        'line-width': 3
        }
      });
    }
  }, [meta])

  useEffect(() => {
    console.log(filters.zoom)
    if(map?.loaded()) {
      console.log('here')
      map.setZoom(filters.zoom)
    }
  },[filters.zoom])
  
  useEffect(() => {
    mapbox.accessToken = mapboxToken;
    map = new mapbox.Map({
      container: 'map-container', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: center || [-73.064034, 41.230698], // starting position [lng, lat]
      zoom:  12, // starting zoomd
      dragPan: true,
    });

    addControls();

    map.on('load', handleMapMoveEnd);
  }, [])

  useEffect(() => {
    if(!filters.city) {
      removePlaceLayer();
    }
    map.on('boxzoomend', handleZoomEnd)
    map.on('moveend', handleMapMoveEnd)
    return () => {
      map.off('moveend', handleMapMoveEnd)
      map.off('boxzoomend', handleZoomEnd)
    }
  }, [filters])

  useEffect(() => {
    map.panTo(center)
  }, [map, center])

  useEffect(() => {
    drawMarkers();
  },[properties])

  const handleZoomEnd = () => {
    if(map.getZoom() > 10) {
      clearMarkers();
    }
  }

  const getPlaceFilter = () => {
    const params = new URLSearchParams(window.location.search)
    const state = params.get('state') || ''
    const city = params.get('city') || ''

    return { city, state }
  }

  const handleMapMoveEnd = () => {
    const zoom = map.getZoom();
    const {_sw, _ne} = map.getBounds();
    const sw = `${_sw.lng},${_sw.lat}`;
    const ne = `${_ne.lng},${_ne.lat}`;
    let newFilters = {...filters, ...{ne, sw, zoom}}
    const { city, state } = getPlaceFilter()
    newFilters = {...newFilters, ...{ city, state }}
    setFilters(newFilters)
  }


  const addControls = () => {
    map.addControl(new mapbox.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    }));

   map.addControl(new mapbox.NavigationControl())
  }

  const clearMarkers = () => {
    markers.forEach((marker) => {
      marker.remove();
    });
  }

  const markerPopUp  = (property) => {
    const bedIcon = renderToString(<Bed color="#004C60" />);
    const bathIcon = renderToString(<Bath color="#004C60" />);
    const squareFeetIcon = renderToString(<SquareFeet color="#004C60"/>);

    const htmlString = `
    <div class="w-60">
      <div class="rounded-lg shadow-lg overflow-hidden">
        <div class="relative">
          <img class="w-full h-40 object-cover" id="marker-image-${property.id}" data-src=${property.cover_photo || placholderImage} alt="Property Image" />
        </div>

        <div class="p-4">
          <p class="text-lg font-semibold capitalize">${property?.selling_price?.toLocaleString("en-US") || 'Unknown'}</p>
          <p class="text-gray-600 capitalize">${property.street_address}</p>
          <p class="text-gray-600 capitalize">${property.city}, ${property.state}, ${property.zip_code}</p>
          <div class="mt-2 flex items-center">
              <div class="mr-2 flex items-center">
                  ${bedIcon} <span class="ml-1">${property.bedrooms} bd</span>
              </div>
              <div class="mr-2 flex items-center">
                  ${bathIcon} <span class="ml-1">${property.bathrooms} ba</span>
              </div>
              <div class="flex items-center">
                  ${squareFeetIcon} <span class="ml-1">${property.square_feet} sqft</span>
              </div>
          </div>
          <div class="mt-4">
            <a href='/properties/${property.id}' class="inline-block rounded-md focus:outline-none bg-teal px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-cyan">
                View Property
            </a>
          </div>

        </div>
      </div>
    </div>
    `
    const popup = new mapbox.Popup({ closeButton: false }).setHTML(htmlString); 
    popup.on('open', loadPopupImages.bind(this, property.id))

    return popup
  }

  // lazy-load images instead of loading on page load
  const loadPopupImages = (propertyId) => {
    // eslint-disable-next-line
    const image = document.querySelector(`#marker-image-${propertyId}`) as HTMLImageElement
    image?.setAttribute('src', image.dataset.src!)
  }

  const drawMarkers = () => {
    clearMarkers()
  
    if(map.getZoom() < 10) return;
    
    markers = properties.map(property => {
      const zoom = map.getZoom();
      const el = document.createElement('div')
      el.setAttribute('data-propertyId', property.id)
      const markerClass = `${property.listing_source}-marker`

      const valuationClasses = property.selling_price && zoom > 12  ? `p-1 text-sm desktop:text-lg rounded-lg ${markerClass}-with-valuation mt-[-10px]` : ' rounded-full w-7 h-7 border-4 border-white'
      el.className = `${markerClass} text-white ${valuationClasses}`
      el.innerText = property.selling_price && zoom > 12 ? `${property.selling_price.toLocaleString("en-US")}` : ''
      
      return new mapbox.Marker(el)
                .setLngLat([property.long, property.lat])
                .setPopup(markerPopUp(property))
                .addTo(map);
    });
  }

  return (
    <div className={'search-sections relative desktop:w-1/2 desktop:mr-4 z-10 overflow-hidden ' + mobileClasses}>
      <div id='map-container' className='w-full h-full'></div>
      <div>
        {children}
      </div>
    </div>
  )
}

export default Map

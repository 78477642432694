import React, { useRef, useEffect, useCallback, useContext, useState } from 'react';
import Button from '../../components/Button';
import CheckBoxColumns from '../../components/CheckBoxColumns';
import HorizontalRadio from '../../components/horizontal-radio';
import RangeInput from '../../components/range-input';
import { PropertyContext } from '../context/property-context';

interface Props {
  setShowFilters: (visible: boolean) => void
  visible: boolean
  buttonRef: React.MutableRefObject<HTMLButtonElement | undefined>
}

const Filters: React.FunctionComponent<Props> = ({ setShowFilters, buttonRef }) => {
  const [localFilters, setLocalFilters] = useState<any>({})
  const { filters, setFilters } = useContext(PropertyContext);
  const parentRef = useRef<HTMLElement>()
  const outsideClick = useCallback((event) => {
    if(!buttonRef || window.innerWidth <= parseInt('1279', 10)) return;

    const el = parentRef.current 
      if(el && !el.contains(event.target) && (event.target !== buttonRef.current)) {
        setShowFilters(false)
      }
  }, [parentRef, buttonRef])

  const homeTypes =  [
    { value: 'house', label: 'House' },
    { value: 'multi_family', label: 'Multi-Family' },
    { value: 'apartment', label: 'Aparment' },
    { value: 'manufactured', label:'Manufactured' },
    { value: 'town_home', label:'Town Home' },
    { value: 'condo', label:'Condo' },
    { value: 'lot', label:'Lot' },
  ]

  const listingSources = [
    { value: 'otto', label: 'Otto Exclusive' },
    { value: 'fsbo', label: 'For Sale By Owner' },
    { value: 'mls', label: 'Agent Listing' }
  ]

  useEffect(() => {
    document.addEventListener('click', outsideClick)
    setLocalFilters({...filters})

    return () => {
      document.removeEventListener('click', outsideClick)
    }
  }, [])

  const updateFilter = (name: string, value: any) => { 
    setLocalFilters({...localFilters, ...{[name]: value}})
  }

  const applyFilters = () => {
    setFilters({...filters, ...localFilters })
    setShowFilters(false);
    logSearch(JSON.stringify(localFilters))
  }
  const logSearch = async (query) => {
    const csrfToken = document.querySelector('meta[name=csrf-token]').content
    const csrfParam = document.querySelector('meta[name=csrf-param]').content
    const body = new FormData();
    body.append(csrfParam, csrfToken)
    body.append('query', query)

    try {
        const result = await fetch(`/log_search`, {
            method: 'POST',
            body
        })
    } catch(e) {
        alert('Something went wrong, please try again.')
    }
  }

  const mobileClass = 'mobile:w-full  mobile:overflow-y-scroll mobile:left-0'

  return (
    <div ref={parentRef} className={"flex flex-col absolute top-0 z-50 bg-white p-4 desktop:border mobile:border-t desktop:border-slate-300 desktop:rounded-lg desktop:drop-shadow-md " + mobileClass}>
      <div className="mb-2  w-full">
        <RangeInput name_prefix='price' label='Price Range' rangeHigh={localFilters.price_high} rangeLow={localFilters.price_low} onChange={updateFilter} />
      </div>
      <div className="mb-2 desktop:w-full">
        <HorizontalRadio name="bedrooms" label="Bedrooms" selected={localFilters.bedrooms} onClick={updateFilter} />
      </div>
      <div className="mb-2 desktop:w-full">
        <HorizontalRadio name="bathrooms" label="Bathrooms" selected={localFilters.bathrooms} onClick={updateFilter} />
      </div>
      <div className="flex flex-col desktop:flex-row">
        <div className="mb-2 desktop:w-full">
          <CheckBoxColumns name="home_type" selected={localFilters.home_type || []} options={homeTypes} onChange={updateFilter} label="Home Types" />
        </div>
        <div className="mb-2 desktop:w-full">
          <CheckBoxColumns name="listing_source" selected={localFilters.listing_source || []} options={listingSources} onChange={updateFilter} label="Listing Type" />
        </div>
      </div>
      <div className="mb-2 desktop:w-full">
        <RangeInput name_prefix="sqft" label="Square Feet" rangeHigh={localFilters.sqft_high} rangeLow={localFilters.sqft_low} onChange={updateFilter} />
      </div>
      <div className="mb-2 desktop:w-full">
        <RangeInput name_prefix="lot_size" label="Lot Size" rangeHigh={localFilters.lot_size_high} rangeLow={localFilters.lot_size_low} onChange={updateFilter} />
      </div>
      <div className="mb-2 desktop:w-full">
        <RangeInput name_prefix="year_built" label="Year Built" rangeHigh={localFilters.year_built_high} rangeLow={localFilters.year_built_low} onChange={updateFilter} />
      </div>
      <div className="text-teal my-4 flex justify-center items-center border-t pt-4">
        <Button classNames="hover:bg-white hover:text-teal text-lg border mr-4 w-40 py-2" onClick={ () => { setShowFilters(false); } }>Close</Button>
        <Button classNames='text-lg w-40 bg-teal text-white py-2' onClick={applyFilters}>Apply</Button>
      </div>
    </div>
  )
}

export default Filters

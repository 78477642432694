import React, { useMemo, useEffect, useRef, useState } from "react";
import placeholderImage from "../../../images/placeholder-image.png";
import { type Property } from "../../types/types";
import OttoBanner from "./OttoBanner";
import OtherBanner from "./OtherBanner";
import FsboBanner from "./FsboBanner";
import PriceCard from "./PriceCard";

interface Props {
  property: Property;
}

const Listing: React.FunctionComponent<Props> = ({ property }) => {
  const [inView, setInView] = useState(false);
  const listingRef = useRef<HTMLDivElement>(null)
  // lazy load images
  useEffect(() => {
    const observer = new IntersectionObserver((entries, obs) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setInView(true);
          obs.disconnect();
        }
      }
    }, {});
    observer.observe(listingRef.current!);
    return () => {
      observer.disconnect();
    }
  }, [])
  const defaultBg = `url(${placeholderImage})`;
  const background = property.cover_photo
    ? `url(${property.cover_photo})`
    : defaultBg;
  const currentBackgound = inView ? background : defaultBg;
  const detailsString = `${property.bedrooms} bedrooms, ${property.bathrooms} bathrooms, ${property.square_feet} sq feet`;
  const addressHTML = useMemo(() => {
    if (property.street_address !== "") {
      return (
        <div className="para-teal-sans mt-2 font-semibold m-0 capitalize">
          <div>
            <p>{`${property.street_address}`}</p>
            <p>{`${property.city}, ${property.state}, ${property.zip_code}`}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="para-teal-sans mt-2 font-semibold m-0 capitalize">
          <div>
            <p>{`Home for sale in ${property.city}, ${property.state}, ${property.zip_code}`}</p>
          </div>
        </div>
      );
    }
  }, [property.street_address]);

  const banner = property.listing_source === 'otto' ? <OttoBanner/> :
      property.listing_source === 'fsbo' ? <FsboBanner/> : <OtherBanner/>

  return (
    <div className="w-1/2 mobile:w-full flex flex-col mb-8 mobile:items-center" ref={listingRef}>
      <a className="block w-11/12" href={`/properties/${property.id}`}>
        <div
          style={{ backgroundImage: currentBackgound }}
          className="h-52 bg-cover bg-center rounded-lg pt-2 relative"
        >
          {banner}
        </div>
        <div>
          {addressHTML}
          <p className="text-sm text-slate-400 mt-1 mb-3">{detailsString}</p>
        </div>
        <div>
          <div className='sm:w-3/5 md:w-11/12 '>
            <PriceCard property={property} />
          </div>
        </div>
      </a>
    </div>
  );
};

export default Listing;

import React from 'react'
import type { ChangeEvent } from 'react'

interface Props {
  options: [{label: string, value: string}]
  name: string
  onChange: (name: string, value: string[]) => void
  selected: any,
  label: string
}

const CheckBoxColumns = ({ options, name, selected, onChange, label }: Props) => {
  const onChoice = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const selectedOpts = [...selected]
    const index = selectedOpts?.indexOf(value)
    if(index > -1) {
      selectedOpts.splice(index, 1)
    } else {
      selectedOpts.push(value)
    }
    onChange(event.target.name, selectedOpts)
  }

  const inputs = options.map(({label, value}) => (
    <div key={value}>
      <input className='mr-1' onChange={onChoice} name={name} value={value} type="checkbox" checked={selected?.includes(value)} />
      <label className='text-teal' htmlFor="">{label}</label>
    </div>
  ))

  return (
    <div className="flex flex-col">
      <label className="text-teal font-semibold">{label}</label>
      {inputs}
    </div>
  )
}

export default CheckBoxColumns;
